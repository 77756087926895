import React from 'react';
import { Link } from 'react-router-dom';
import { AlertTriangle } from 'lucide-react';

const ErrorAlert = ({ message }) => {
  return (
    <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
      <div className="flex items-center">
        <AlertTriangle className="h-5 w-5 mr-2" />
        <strong className="font-bold">Error:</strong>
        <span className="block sm:inline ml-1">{message}</span>
      </div>
      <div className="mt-2">
        <Link to="/dashboard" className="text-red-700 underline hover:text-red-800">
          Return to Dashboard
        </Link>
      </div>
    </div>
  );
};

export default ErrorAlert;