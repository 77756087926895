import React, { useEffect, useState } from 'react';

import Plot from 'react-plotly.js';
import customPlotlyTheme from './plotlyTheme';
import { Spinner } from "../ui/spinner";

export const PosPnlBySymbol = ({ data }) => {
  const [chartData, setChartData] = useState([]);
  const [layout, setLayout] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (data && data.traderData?.position_pnl_by_symbol) {
      const heatmap = data.traderData.position_pnl_by_symbol;

      const positiveColors = ['#98FB98', '#32CD32'];
      const negativeColors = ['#F08080', '#FF0000'];

      // Create trace for rectangles
      const rectangles = heatmap.heatmap_data.map(entry => {
        const y = heatmap.symbols.indexOf(entry.symbol);
        const color = entry.pnl > 0
          ? positiveColors[Math.floor((entry.pnl / heatmap.max_pnl) * (positiveColors.length - 1))]
          : negativeColors[Math.floor((Math.abs(entry.pnl) / Math.abs(heatmap.min_pnl)) * (negativeColors.length - 1))];

        return {
          x: [entry.opened, entry.closed, entry.closed, entry.opened, entry.opened],
          y: [y - 0.4, y - 0.4, y + 0.4, y + 0.4, y - 0.4],
          mode: 'lines',
          fill: 'toself',
          fillcolor: color,
          line: { color: 'white', width: 1 },
          type: 'scatter',
          hoverinfo: 'text',
          text: `Symbol: ${entry.symbol}<br>PnL: $${entry.pnl.toFixed(2)}`,
          showlegend: false,
        };
      });

      // Create scatter trace for markers
      const markers = {
        x: [],
        y: [],
        mode: 'markers',
        type: 'scatter',
        marker: {
          symbol: 'circle',
          size: 10,
          color: 'blue',
          opacity: 1,
          line: { color: 'white', width: 1 }
        },
        hoverinfo: 'none',
        showlegend: false
      };

      heatmap.heatmap_data
        .filter(entry => entry.is_long === 0)
        .forEach(entry => {
          const y = heatmap.symbols.indexOf(entry.symbol);
          const openedTime = new Date(entry.opened).getTime();
          const closedTime = new Date(entry.closed).getTime();
          const xCenter = new Date((openedTime + closedTime) / 2);
          markers.x.push(xCenter);
          markers.y.push(y);
        });

      setChartData([...rectangles, markers]);
      setLayout({
        ...customPlotlyTheme,
        xaxis: {
          type: 'date',
          autorange: true,
          tickformatstops: [
            {dtickrange: [null, 1000*60*60*24], value: '%H:%M\n%b %d'},
            {dtickrange: [1000*60*60*24, 1000*60*60*24*30], value: '%b %d'},
            {dtickrange: [1000*60*60*24*30, null], value: '%b %Y'}
          ],
          tickangle: -45,
          nticks: 15,
          automargin: true,
        },
        yaxis: {
          tickvals: [...Array(heatmap.symbols.length).keys()],
          ticktext: heatmap.symbols.map(symbol => symbol.length > 10 ? symbol.substring(0, 10) + '...' : symbol),
          categoryorder: 'category descending',
          tickangle: 0,
          automargin: true,
        },
        autosize: true,
        height: 600,
        margin: { t: 10, r: 10, l: 60, b: 100 }, // Increased bottom margin
        showlegend: false,
        hoverlabel: { bgcolor: "#FFF", font: { color: "#000" } },
      });

      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  }, [data]);

  if (isLoading) {
    return <Spinner size="large" text="Loading..." />;
  }

  if (!data || !data.traderData?.position_pnl_by_symbol) {
    return <div>No data available for Position PnL by Symbol chart</div>;
  }

  return (
    <div>
      <Plot
        data={chartData}
        layout={layout}
        style={{ width: '100%', height: '100%' }}
        useResizeHandler={true}
        config={{ responsive: true }}
      />
      <div className="mt-2 text-sm text-gray-500 italic">
        Note: Blue circle markers identify short positions
      </div>
    </div>
  );
};
