import React from 'react';
import { AlertTriangle } from 'lucide-react';

const PositionLoadError = ({ message }) => {
  return (
    <div className="bg-yellow-100 border border-yellow-400 text-yellow-700 px-4 py-3 rounded relative" role="alert">
      <div className="flex items-center">
        <AlertTriangle className="h-5 w-5 mr-2" />
        <strong className="font-bold">Unable to load position data:</strong>
        <span className="block sm:inline ml-1">{message}</span>
      </div>
      <div className="mt-2 text-sm">
        <p>This could be due to the scheduled positions updated which runs every few minutes. You can try again in a few minutes.</p>
      </div>
    </div>
  );
};

export default PositionLoadError;