import React from 'react';
import { TWITTER_PROFILE_URL } from '../../utils/config';
const NotesOnBeta = () => {
  return (
    <div className="border border-gray-700 rounded-lg p-4 mt-8">
      <h3 className="text-lg font-semibold text-white mb-2">Notes</h3>
      <p className="text-gray-300 text-sm">
        As HyperTracker is currently in beta, please be aware that you may encounter some bugs or inconsistencies. 
        As the historical data from Hyperliquid is limited, there may be cases where all fills are not available. 
        <br></br><br></br>
        The "Coverage (%)" metric shown in the tables above measures the percentage of positions which are completely covered by fills.
        For example, if the metric shows 90%, it means 90% of the positions have all their fills, while 10% of the positions
        have one or more missing fills.
        <br></br><br></br>
        If you notice any issues or have feedback for improvement, please DM on{' '}
        <a
          href={TWITTER_PROFILE_URL}
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-400 hover:text-blue-300"
        >
          X
        </a>.
      </p>
    </div>
  );
};

export default NotesOnBeta;