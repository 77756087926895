import { useState, useEffect } from 'react';
import { getDashboardData } from '../services/jsonApi';
import { shortenAddress } from '../utils/helpers';
import { formatUTCDate } from '../utils/helpers';
const formatNumber = (num) => {
  return new Intl.NumberFormat('en-US').format(num);
};

const formatData = (data) => {
  return data.map(item => ({
    name: item.name,
    user_id: item.user_id,
    user_id_short: shortenAddress(item.user_id),
    WinRate: item.WinRate ? (item.WinRate * 100).toFixed(1) + '%' : 'N/A',
    TotalPnL: item.TotalPnL ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(item.TotalPnL) : 'N/A',
    ProfitFactor: item.ProfitFactor ? item.ProfitFactor.toFixed(2) : 'N/A',
    TotalPositionsTrades: `${formatNumber(item.TotalPositions || 0)} [${formatNumber(item.TotalTrades || 0)}]`,
    FirstTrade: item.FirstTrade ? item.FirstTrade.split('T')[0] : '',
    LastTrade: item.LastTrade ? item.LastTrade.split('T')[0] : '',
    CoverageAll: item.CoverageAll ? (item.CoverageAll * 100).toFixed(1) + '%' : 'N/A',
  }));
};

export const useDashboardData = () => {
  const [userData, setUserData] = useState([]);
  const [vaultData, setVaultData] = useState([]);
  const [createdAt, setCreatedAt] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getDashboardData();
        const { data, created_at } = response[0];
        
        if (data) {
          setUserData(formatData(data.user || []));
          setVaultData(formatData(data.vault || []));
          setCreatedAt(formatUTCDate(created_at) + ' UTC');
        }
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return { userData, vaultData, createdAt, loading, error };
};