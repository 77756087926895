import { useState, useEffect } from 'react';
import { getProfilePositionsData } from '../services/jsonApi';

export const useProfilePositionsData = (id) => {
  const [positionsData, setPositionsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getProfilePositionsData(id);
        setPositionsData(response);
      } catch (err) {
        console.error('Error fetching profile positions data:', err);
        setError(err.message || 'An error occurred while fetching positions data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  return { positionsData, loading, error };
};