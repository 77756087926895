import React from 'react';

const PageHeader = ({ title, description }) => {
  return (
    <div className="mb-6">
      <h1 className="text-2xl text-primary font-bold mb-2">{title}</h1>
      <p className="text-gray-300">{description}</p>
    </div>
  );
};

export default PageHeader;