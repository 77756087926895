import React from 'react';
import { CheckCircle, Circle, Clock } from 'lucide-react';

const features = [
  { id: 1, text: 'Launch of HyperTracker Beta [December 2024]', completed: true },
  { id: 2, text: 'Improve separation between open and closed positions', completed: false, upcoming: true },
  { id: 3, text: 'Add real-time data on open positions', completed: false, upcoming: true },
  { id: 4, text: 'Add more portfolio metrics (balance, margin, etc.)', completed: false },
  { id: 5, text: 'Add filters, sorting, and performance metrics across different timeframes', completed: false },
  { id: 6, text: 'Enable smart copy trading for traders', completed: false },
  { id: 7, text: 'Create a dashboard with aggregated metrics for traders and vaults', completed: false }, 
  { id: 8, text: 'Distinguish between spot and perpetual trading', completed: false }, 
  { id: 9, text: '...', completed: false },
];


export default function RoadmapFeatures() {
  return (
    <div className="mt-8">
      <h2 className="text-2xl font-semibold mb-4">Backlog of main items</h2>
      <ul className="space-y-1 mb-4">
        {features.map((feature) => (
          <li 
            key={feature.id} 
            className="flex items-center p-1"
          >
            {feature.completed ? (
              <CheckCircle className="text-green-500 mr-2" />
            ) : feature.upcoming ? (
              <Clock className="text-blue-500 mr-2" />
            ) : (
              <Circle className="text-gray-400 mr-2" />
            )}
            <span className={feature.completed ? 'text-gray-500' : ''}>
              {feature.text}
            </span>
          </li>
        ))}
      </ul>
      <div className="flex space-x-4 text-sm">
        <div className="flex items-center">
          <CheckCircle className="text-green-500 mr-1 h-4 w-4" />
          <span>Completed</span>
        </div>
        <div className="flex items-center">
          <Circle className="text-gray-400 mr-1 h-4 w-4" />
          <span>In Progress</span>
        </div>
        <div className="flex items-center">
          <Clock className="text-blue-500 mr-1 h-4 w-4" />
          <span>Upcoming</span>
        </div>
      </div>
    </div>
  );
}