import React from 'react';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../ui/card";
import { formatHoldingTime, formatPercentage, formatNumber } from '../../utils/helpers';

const ProfileMetricsCards = ({ traderMetricsData }) => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 px-6"> {/* Added px-6 for horizontal alignment */}
      {[
        { title: "Total PnL ($)", description: "Overall profit and loss (before fees)", key: "TotalPnL", format: (v) => `$${formatNumber(v)}` },
        { title: "Win Rate", description: "Percentage of winning trades", key: "WinRate", format: formatPercentage },
        { title: "Profit Factor", description: "Ratio of gross profit to gross loss", key: "ProfitFactor", format: formatNumber },
        { title: "Symbols Traded", description: "Number of different assets traded", key: "SymbolsTraded", format: (v) => v.toString() },
        { title: "Total Positions", description: "Number of positions tracked", key: "TotalPositions", format: (v) => v.toString() },
        { title: "Avg Holding Time", description: "Average duration of all trades", key: "AvgHoldingAll", format: formatHoldingTime },
      ].map((metric) => (
        <Card key={metric.key} className="w-full h-full">
          <CardHeader className="p-4">
            <CardTitle className="text-lg">{metric.title}</CardTitle>
            <CardDescription className="text-sm">{metric.description}</CardDescription>
          </CardHeader>
          <CardContent className="p-4 pt-0">
            {traderMetricsData?.[metric.key] !== undefined ? (
              <p className="text-xl font-bold">
                {metric.format(traderMetricsData[metric.key])}
              </p>
            ) : (
              <p className="text-sm">Data not available</p>
            )}
          </CardContent>
        </Card>
      ))}
    </div>
  );
};

export default ProfileMetricsCards;