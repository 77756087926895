import { useState, useEffect } from 'react';
import { getProfileMetricsData } from '../services/jsonApi';

export const useProfileMetricsData = (id) => {
  const [traderMetricsData, setTraderMetricsData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getProfileMetricsData(id);
        const data = response[0]?.data || null;
        setTraderMetricsData(data);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  return { traderMetricsData, loading, error };
};