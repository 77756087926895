import React from 'react';
import { useDashboardData } from '../hooks/useDashboardData';
import PageHeader from '../components/common/PageHeader';
import Table from '../components/common/Table';
import { Spinner } from "../components/ui/spinner";
import PageContainer from "../components/common/PageContainer";
import ErrorAlert from "../components/ui/ErrorAlert";
import NotesOnBeta from '../components/common/NotesOnBeta'; // Import the new component

export default function Dashboard() {
  const { userData, vaultData, createdAt, loading, error } = useDashboardData();

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        align: 'left', 
      },
      {
        Header: 'Address',
        accessor: 'user_id_short',
        
      },
      {
        Header: 'Total Pnl ($)',
        accessor: 'TotalPnL',
      },
      {
        Header: 'Win Rate (%)',
        accessor: 'WinRate',
      },
      {
        Header: 'Profit Factor',
        accessor: 'ProfitFactor',
      },
      {
        Header: '# Positions [Trades]',
        accessor: 'TotalPositionsTrades',
      },
      {
        Header: 'Tracked Since',
        accessor: 'FirstTrade',
      },
      {
        Header: 'Last Trade',
        accessor: 'LastTrade',
      },
      {
        Header: 'Coverage (%)',
        accessor: 'CoverageAll',
      },
    ],
    []
  );

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Spinner size="large" text="Loading dashboard data..." />
      </div>
    );
  }

  if (error) {
    return (
      <PageContainer>
        <ErrorAlert message={error.message} />
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <PageHeader 
        title="Dashboard" 
        description={
          <>
            {createdAt && (
              <span className="text-gray-500">
                Last updated: {createdAt}
              </span>
            )}
          </>
        }
      />
      <div className="flex flex-col space-y-8">
        <Table 
          columns={columns} 
          data={userData} 
          title="Traders" 
          linkUrl={(row) => `/dashboard/profile/${row.user_id}`}
        />
        <Table 
          columns={columns} 
          data={vaultData} 
          title="Vaults" 
          linkUrl={(row) => `/dashboard/profile/${row.user_id}`}
        />
        <NotesOnBeta /> 
      </div>
    </PageContainer>
  );
}