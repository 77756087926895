import React from 'react';

const Badge = ({ children, color = 'yellow' }) => {
  const colorClasses = {
    green: 'bg-green-100 text-green-800',
    red: 'bg-red-100 text-red-800',
    yellow: 'bg-yellow-400 text-black',
    gray: 'bg-gray-100 text-gray-800',
    blue: 'bg-blue-200 text-blue-800',
    white: 'bg-white text-black',
  };

  return (
    <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${colorClasses[color] || colorClasses.yellow}`}>
      {children}
    </span>
  );
};

export default Badge;