import React from 'react'
import { Link } from 'react-router-dom';
import { TWITTER_PROFILE_URL } from '../../utils/config';

const XIcon = () => (
  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z" fill="currentColor"/>
  </svg>
);

export function Footer() {
  return (
    <footer className="bg-background border-t border-gray-800">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 w-full py-2">
        <div className="flex flex-wrap justify-between items-center text-sm">
          <div className="w-auto">
            <span className="text-secondary">
              © {new Date().getFullYear()} HyperTracker™. All rights reserved.
            </span>
          </div>
          <div className="w-auto">
            <ul className="flex items-center space-x-4">
              {/* Commented out Privacy and Terms links
              <li>
                <Link to="/" className="text-secondary hover:text-primary">
                  Privacy
                </Link>
              </li>
              <li>
                <Link to="/" className="text-secondary hover:text-primary">
                  Terms
                </Link>
              </li>
              */}
              <li>
                <a href={TWITTER_PROFILE_URL} target="_blank" rel="noopener noreferrer" className="text-secondary hover:text-primary">
                  <XIcon />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  )
}
