const showAnnotation = true; // You can change this to false to hide the annotation

const customPlotlyTheme = {
  paper_bgcolor: 'hsl(224, 71%, 4%)', // --background
  plot_bgcolor: 'hsl(224, 71%, 4%)', // --background
  font: {
    color: 'hsl(213, 31%, 91%)', // --foreground
    family: 'Inter, system-ui, sans-serif'
  },
  xaxis: {
    color: 'hsl(213, 31%, 91%)', // --foreground
    title: {
      standoff: 10,
      font: {
        color: 'hsl(215.4, 16.3%, 56.9%)' // --muted-foreground
      }
    },
    tickfont: {
      color: 'hsl(213, 31%, 91%)' // --foreground
    },
    gridcolor: 'hsl(216, 34%, 17%)',
    linecolor: 'hsl(216, 34%, 17%)',
    zerolinecolor: 'hsl(216, 34%, 17%)',
    showgrid: false,
    zeroline: true,
    zerolinewidth: 1,
    // ... other properties ...
  },
  yaxis: {
    color: 'hsl(213, 31%, 91%)', // --foreground
    title: {
      standoff: 10,
      font: {
        color: 'hsl(215.4, 16.3%, 56.9%)' // --muted-foreground
      }
    },
    tickfont: {
      color: 'hsl(213, 31%, 91%)' // --foreground
    },
    gridcolor: 'hsl(216, 34%, 17%)',
    linecolor: 'hsl(216, 34%, 17%)',
    zerolinecolor: 'hsl(216, 34%, 17%)',
    showgrid: true,
    gridwidth: 1,
    zeroline: true,
    zerolinewidth: 1,
    // ... other properties ...
  },
  legend: {
    font: {
      color: 'hsl(213, 31%, 91%)' // --foreground
    },
    bgcolor: 'hsl(224, 71%, 4%)' // --background
  },
  title: {
    font: {
      color: 'hsl(213, 31%, 91%)' // --foreground
    }
  },
  sourceText: 'Source: HyperTracker',
  annotations: showAnnotation ? [
    {
      text: 'Source: HyperTracker.xyz',
      xref: 'paper',
      yref: 'paper',
      x: 0,
      y: -0.14,
      xanchor: 'left',
      yanchor: 'top',
      showarrow: false,
      font: {
        size: 10,
        color: 'rgba(255, 255, 255, 0.7)'
      }
    }
  ] : [],

  // ... other properties ...
};

export default customPlotlyTheme;