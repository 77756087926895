import React from "react";
import HeroContent from "./HeroContent";
import { Button } from "../ui/button";
import { Link } from "react-router-dom";
import PageContainer from "../common/PageContainer";

export function HeroSection() {
    return (
        <section className="relative w-full bg-background">
            <PageContainer>
                <HeroContent />
                <div className="flex justify-center mb-8">
                    <Link to="/dashboard">
                        <Button variant="default" size="lg">Get Started</Button>
                    </Link>
                </div>
            </PageContainer>
        </section>
    );
}