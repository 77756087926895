import React, { useMemo, useState, useCallback } from "react";
import { useParams, Link } from "react-router-dom";
import { useProfileData } from '../hooks/useProfileData';
import { useProfileMetricsData } from '../hooks/useProfileMetricsData';
import { useProfilePositionsData } from '../hooks/useProfilePositionsData';
import { getPositionTradesData } from '../services/jsonApi';
import { PnlBySymbolPlotly } from "../components/charts/PnLBySymbol";
import { PosPnlBySymbol } from "../components/charts/PosPnlBySymbol";
import { Notional } from "../components/charts/Notional";
import { shortenAddress, formatEntryPrice, copyToClipboard } from '../utils/helpers';
import { Spinner } from "../components/ui/spinner";
import PageHeader from "../components/common/PageHeader";
import PageContainer from "../components/common/PageContainer";
import SectionHeader from "../components/common/SectionHeader";
import { SquareArrowOutUpRight, ArrowLeft, Copy } from 'lucide-react';
import ProfileMetricsCards from "../components/profile/ProfileMetricsCards";
import Badge from "../components/ui/Badge";
import ErrorAlert from "../components/ui/ErrorAlert";
import ScrollToTopButton from "../components/ui/ScrollToTopButton";
import Table from "../components/common/Table";
import PositionDetailChart from "../components/charts/PositionDetailChart";
import PositionLoadError from "../components/ui/PositionLoadError";
import { formatUTCDate } from "../utils/helpers";

export default function Profile() {
  const { id } = useParams();
  const { traderData, loading: loadingProfile, error: errorProfile } = useProfileData(id);
  const { traderMetricsData, loading: loadingMetrics, error: errorMetrics } = useProfileMetricsData(id);
  const { positionsData, loading: loadingPositions, error: errorPositions } = useProfilePositionsData(id);
  const id_short = shortenAddress(id);
  const hyperliquidUrl = `https://app.hyperliquid.xyz/explorer/address/${id}`;

  const columns = useMemo(
    () => [
      {
        Header: 'Symbol',
        accessor: 'symbol',
        align: 'left',
      },
      {
        Header: 'Side',
        accessor: 'is_long',
        Cell: ({ value }) => (value ? 'Long' : 'Short'),
        align: 'center',
      },
      {
        Header: 'First Trade',
        accessor: 'opened',
        Cell: ({ value }) => formatUTCDate(value),
        align: 'right',
      },
      {
        Header: 'Last Trade',
        accessor: 'closed',
        Cell: ({ value }) => formatUTCDate(value),
        align: 'right',
      },
      {
        Header: 'Entry Price',
        accessor: 'avg_entry_price',
        Cell: ({ value }) => formatEntryPrice(value),
        align: 'right',
      },
      {
        Header: 'Max Size',
        accessor: 'max_notional_value',
        Cell: ({ value }) => `$${parseFloat(value).toLocaleString('en-US', { minimumFractionDigits: 1, maximumFractionDigits: 1 })}`,
        align: 'right',
      },
      {
        Header: 'PnL ($)',
        accessor: 'cum_position_pnl',
        Cell: ({ value }) => {
          const numValue = parseFloat(value);
          const formattedValue = numValue.toLocaleString('en-US', { minimumFractionDigits: 1, maximumFractionDigits: 1 });
          const textColor = numValue < 0 ? 'text-red-500' : 'text-green-500';
          return <span className={textColor}>${formattedValue}</span>;
        },
        align: 'right',
      },
      {
        Header: 'ROI (%)',
        accessor: 'roi',
        Cell: ({ value }) => `${(parseFloat(value) * 100).toFixed(2)}%`,
        align: 'center',
      },
      {
        Header: 'Status',
        accessor: 'is_closed',
        Cell: ({ value }) => (value ? 'Closed' : 'Open'),
        align: 'center',
      },
      {
        Header: 'Coverage',
        accessor: 'is_broken',
        Cell: ({ value }) => (
          <span className={`inline-block w-2 h-2 rounded-full ${value ? 'bg-red-500' : 'bg-green-500'}`}></span>
        ),
        align: 'center',
      },
    ],
    []
  );

  const [copyMessage, setCopyMessage] = useState("");

  const handleCopy = () => {
    copyToClipboard(
      id,
      () => {
        setCopyMessage("Address copied");
        setTimeout(() => setCopyMessage(""), 500);
      },
      () => {
        setCopyMessage("Failed to copy");
        setTimeout(() => setCopyMessage(""), 500);
      }
    );
  };

  const [expandedPositionData, setExpandedPositionData] = useState({});
  const [expandedPositionErrors, setExpandedPositionErrors] = useState({});

  const handleRowExpand = useCallback(async (rowId, positionId) => {
    if (!expandedPositionData[positionId]) {
      try {
        const data = await getPositionTradesData(positionId);
        setExpandedPositionData(prev => ({
          ...prev,
          [positionId]: data
        }));
      } catch (error) {
        console.error('Error fetching position data:', error);
        setExpandedPositionErrors(prev => ({
          ...prev,
          [positionId]: error.message || 'Failed to load position data'
        }));
      }
    }
  }, [expandedPositionData]);

  const renderRowSubComponent = useCallback(
    ({ row }) => {
      const positionId = row.original.position_id;
      const data = expandedPositionData[positionId];
      const error = expandedPositionErrors[positionId];

      if (error) {
        return <PositionLoadError message={error} />;
      }

      if (!data) {
        return <Spinner size="large" text="Loading position data..." />;
      }

      return (
        <PositionDetailChart
          data={data}
          symbol={data.trades[0].symbol}
          traderName={data.trades[0].trader_name || 'Unknown Trader'}
        />
      );
    },
    [expandedPositionData, expandedPositionErrors]
  );

  if (loadingProfile || loadingMetrics || loadingPositions) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Spinner size="large" text="Loading trader data...it may take a while" />
      </div>
    );
  }

  if (errorProfile || errorMetrics || errorPositions) {
    return (
      <PageContainer>
        <ErrorAlert message={(errorProfile || errorMetrics || errorPositions).message} />
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <div className="mb-8">
        <Link
          to="/dashboard"
          className="flex items-center text-gray-400 hover:text-custom-yellow transition-colors mb-4"
        >
          <ArrowLeft className="mr-2 h-4 w-4" />
          Back to Dashboard
        </Link>
        <PageHeader 
          title="Performance Details" 
          description={
            <div className="flex items-center">
              {traderMetricsData.name && traderMetricsData.name !== '-' && (
                <div className="mr-4">
                  <Badge color="blue" className="mr-2">{traderMetricsData.name}</Badge>
                </div>
              )}
              <span>Address: </span>
              <span className="flex items-center ml-1">
                <span className="text-custom-yellow">{id_short}</span>
                <div className="relative">
                  <button
                    onClick={handleCopy}
                    className="ml-1 text-gray-400 hover:text-custom-yellow"
                    title="Copy full address"
                  >
                    <Copy className="h-4 w-4" />
                  </button>
                  {copyMessage && (
                    <span className="absolute top-full left-1/2 transform -translate-x-1/2 bg-gray-800 text-white text-xs rounded py-1 px-2 mt-1 whitespace-nowrap">
                      {copyMessage}
                    </span>
                  )}
                </div>
                <a 
                  href={hyperliquidUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="ml-1 text-gray-400 hover:text-custom-yellow"
                  title="Open in Hyperliquid"
                >
                  <SquareArrowOutUpRight className="h-4 w-4" />
                </a>
              </span>
            </div>
          }
        />
      </div>
      
      <div className="flex flex-col space-y-8">
        <section className="w-full">
          <SectionHeader 
            title="Key Metrics" 
            description="Key Metrics are calculated based on all positions tracked. Data may be different from Hyperliquid."
          />
          <ProfileMetricsCards traderMetricsData={traderMetricsData} />
        </section>

        {/* Add separator here */}
        {/* <hr className="border-gray-700" /> */}

        {/* Add centered informative text */}
        <p className="text-sm text-gray-400 italic text-center">
          All data shown below is calculated based on the recent 1000 positions at maximum.
        </p>

        <section className="w-full border border-gray-700 rounded-lg p-6">
          <SectionHeader 
            title="Notional Exposure & Pnl ($)" 
            description="The total value of positions held by this trader"
          />
          <Notional data={{ traderData }} />
        </section>
        <section className="w-full border border-gray-700 rounded-lg p-6">
          <SectionHeader 
            title="PnL by Symbol ($)" 
            description="Profit and Loss breakdown for each trading symbol"
          />
          <PnlBySymbolPlotly data={{ traderData }} />
        </section>
        <section className="w-full border border-gray-700 rounded-lg p-6">
          <SectionHeader 
            title="Position PnL by Symbol ($)" 
            description="Profit and Loss for current open positions by symbol"
          />
          <PosPnlBySymbol data={{ traderData }} />
        </section>
        <section className="w-full border border-gray-700 rounded-lg p-6">
          <SectionHeader 
            title="Positions" 
            description="Open and closed positions of the trader"
          />
          {positionsData && positionsData.length > 0 ? (
            <Table 
              columns={columns} 
              data={positionsData} 
              //linkUrl={(row) => `/position/${row.position_id}`}
              expandable={true}
              renderRowSubComponent={renderRowSubComponent}
              onRowExpand={handleRowExpand}
            />
          ) : (
            <p>No positions data available for this trader.</p>
          )}
        </section>
      </div>

      <ScrollToTopButton />
    </PageContainer>
  );
}
