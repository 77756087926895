import React, { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';
import customPlotlyTheme from './plotlyTheme';
import { Spinner } from "../ui/spinner";

export const Notional = ({ data }) => {
  const [chartData, setChartData] = useState([]);
  const [layout, setLayout] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (data && data.traderData && data.traderData.notional_and_pnl_by_time) {
      const times = data.traderData.notional_and_pnl_by_time.map(entry => entry.date);
      const longNotional = data.traderData.notional_and_pnl_by_time.map(entry => entry.long_notional);
      const shortNotional = data.traderData.notional_and_pnl_by_time.map(entry => entry.short_notional);
      const netExposure = data.traderData.notional_and_pnl_by_time.map(entry => entry.net_exposure);
      const cumPositionPnl = data.traderData.notional_and_pnl_by_time.map(entry => entry.cum_position_pnl || 0);

      const chartData = [
        {
          x: times,
          y: longNotional,
          type: 'scatter',
          mode: 'lines',  // Changed from 'none' to 'lines'
          fill: 'tozeroy',
          name: 'Long Notional',
          fillcolor: 'hsla(160, 60%, 45%, 0.4)', // Green with transparency
          line: { 
            color: 'hsla(160, 60%, 45%, 0.8)', // Solid green line
            width: 1 // Add line width for visibility
          },
          xaxis: 'x',
          yaxis: 'y3',
        },
        {
          x: times,
          y: shortNotional,
          type: 'scatter',
          mode: 'lines',  // Changed from 'none' to 'lines'
          fill: 'tozeroy',
          name: 'Short Notional',
          fillcolor: 'hsla(0, 60%, 45%, 0.4)', // Red with transparency
          line: { 
            color: 'hsla(0, 60%, 45%, 0.8)', // Solid red line
            width: 1 // Add line width for visibility
          },
          xaxis: 'x',
          yaxis: 'y3',
        },
        {
          x: times,
          y: netExposure,
          type: 'bar',  // Changed from 'scatter' to 'bar'
          name: 'Net Notional Exposure',
          marker: {
            color: 'rgba(255, 255, 255, 0.7)',  // Light gray color
            line: {
              color: 'rgba(255, 255, 255, 0.9)',  // Slightly darker outline
              width: 0
            }
          },
          xaxis: 'x',
          yaxis: 'y2',
        },
        {
          x: times,
          y: cumPositionPnl,
          type: 'scatter',
          mode: 'lines',
          name: 'PnL ($)',
          line: { 
            color: 'rgba(255, 255, 255, 0.8)', // Changed to white
            width: 2 // Increased width for better visibility
          },
          xaxis: 'x',
          yaxis: 'y',
        }
      ];

      setChartData(chartData);
      setLayout({
        ...customPlotlyTheme,
        autosize: true,
        height: 750,
        margin: { t: 30, r: 10, l: 70, b: 40 },
        grid: { rows: 3, columns: 1, pattern: 'independent', roworder: 'bottom to top' },
        xaxis: { 
          ...customPlotlyTheme.xaxis,
          title: 'Time',
          domain: [0, 1],
          anchor: 'y',
          showgrid: false,
        },
        yaxis: { 
          ...customPlotlyTheme.yaxis,
          title: {
            text: 'PnL ($)',
            standoff: 30
          },
          domain: [0, 0.25],
          showgrid: false,
        },
        yaxis2: { 
          ...customPlotlyTheme.yaxis,
          title: {
            text: 'Net Notional Exposure',
            standoff: 30
          },
          domain: [0.30, 0.55],
          showgrid: false,
          zeroline: true,
          zerolinecolor: 'hsl(216, 34%, 17%)',
          zerolinewidth: 1,
        },
        yaxis3: { 
          ...customPlotlyTheme.yaxis,
          title: {
            text: 'Notional Value',
            standoff: 30
          },
          domain: [0.60, 1],
          showgrid: false,
        },
        legend: {
          orientation: "h",
          yanchor: "top",
          y: -0.08,  
          xanchor: "center",
          x: 0.5,
          bgcolor: 'rgba(0,0,0,0)',  // Transparent background
          bordercolor: 'rgba(0,0,0,0)'  // No border
        }
      });
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  }, [data]);

  if (isLoading) {
    return <Spinner size="large" text="Loading..." />;
  }

  if (!data || !data.traderData || !data.traderData.notional_and_pnl_by_time) {
    return <div>No data available for Notional chart</div>;
  }

  return (
    <Plot
      data={chartData}
      layout={layout}
      style={{ width: "100%", height: "100%" }}
      useResizeHandler={true}
      config={{ responsive: true }}
    />
  );
};