import axios from 'axios';

// TODO: modify for production
const baseURL = process.env.REACT_APP_BASE_URL || "http://localhost:5001";

const API = axios.create({ baseURL: baseURL, withCredentials: true });

export const getDashboardData = async () => {
  try {
    const response = await API.get("/api/dashboard");
    return response.data;
  } catch (error) {
    console.error("Fetching data failed:", error);
  }
};

export const getProfileData = async (id) => {
  try {
    const response = await API.get(`/api/profile/${id}`);
    return response.data;
  } catch (error) {
    console.error("Fetching data failed:", error);
  }
};

export const getProfileMetricsData = async (id) => {
  try {
    const response = await API.get(`/api/profileMetrics/${id}`);
    return response.data;
  } catch (error) {
    console.error("Fetching data failed:", error);
  }
};

export const getProfilePositionsData = async (id) => {
  try {
    const response = await API.get(`/api/positions/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching positions data:', error);
    throw error;
  }
};

export const getPositionTradesData = async (id) => {
  try {
    const response = await API.get(`/api/positionTrades/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching position trades data:', error);
    throw error;
  }
};