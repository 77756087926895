import React, { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';
import customPlotlyTheme from './plotlyTheme';
import { Spinner } from "../ui/spinner";
import { formatUTCDate } from '../../utils/helpers';

export const PositionDetailChart = ({ data, symbol, traderName }) => {
  const [chartData, setChartData] = useState([]);
  const [layout, setLayout] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (data && data.candles && data.trades) {
      //console.log('Preparing chart data', { symbol, traderName });
      const candleData = {
        x: data.candles.map(candle => new Date(candle.t).toISOString()),
        open: data.candles.map(candle => candle.o),
        high: data.candles.map(candle => candle.h),
        low: data.candles.map(candle => candle.l),
        close: data.candles.map(candle => candle.c),
        type: 'candlestick',
        xaxis: 'x',
        yaxis: 'y',
        name: 'Price',
        increasing: {line: {color: '#26a69a'}, fillcolor: '#26a69a'},
        decreasing: {line: {color: '#ef5350'}, fillcolor: '#ef5350'},
        line: {width: 1},
        whiskerwidth: 0,
      };

      const avgEntryPrice = {
        x: data.trades.map(trade => new Date(trade.seen).toISOString()),
        y: data.trades.map(trade => trade.avg_entry_price),
        type: 'scatter',
        mode: 'lines',
        line: { color: 'brown', width: 3 },
        name: 'Avg. Entry',
        xaxis: 'x',
        yaxis: 'y'
      };

      const notionalValue = {
        x: data.trades.map(trade => new Date(trade.seen).toISOString()),
        y: data.trades.map(trade => trade.notional_value),
        type: 'scatter',
        mode: 'lines',  // Changed from 'markers' to 'lines'
        line: { color: 'white', width: 2 },  // Added line properties
        name: 'Notional Value',
        xaxis: 'x',
        yaxis: 'y2'
      };

      const positionPnl = {
        x: data.trades.map(trade => new Date(trade.seen).toISOString()),
        y: data.trades.map(trade => trade.cum_position_pnl),
        type: 'scatter',
        mode: 'lines',
        line: { color: 'white', width: 3 },
        name: 'Position PnL ($)',
        xaxis: 'x',
        yaxis: 'y3'
      };

      const realizedPnl = {
        x: data.trades.map(trade => new Date(trade.seen).toISOString()),
        y: data.trades.map(trade => trade.cum_realized_position_pnl),
        type: 'scatter',
        mode: 'lines',
        line: { color: 'green', width: 2 },
        name: 'Realized PnL ($)',
        xaxis: 'x',
        yaxis: 'y3'
      };

      /*
      const unrealizedPnl = {
        x: data.trades.map(trade => new Date(trade.seen).toISOString()),
        y: data.trades.map(trade => trade.unrealized_position_pnl),
        type: 'scatter',
        mode: 'lines',
        line: { color: 'orange', width: 2 },
        name: 'Unrealized PnL ($)',
        xaxis: 'x',
        yaxis: 'y3'
      };
      */

      const increaseTrades = data.trades.filter(trade => trade.is_increase);
      const decreaseTrades = data.trades.filter(trade => !trade.is_increase);

      // Calculate the range of trade quantities
      const allQuantities = data.trades.map(trade => Math.abs(trade.quantity));
      const minQuantity = Math.min(...allQuantities);
      const maxQuantity = Math.max(...allQuantities);

      // Function to normalize trade sizes
      const normalizeSize = (quantity, minSize = 5, maxSize = 20) => {
        if (minQuantity === maxQuantity) return (minSize + maxSize) / 2;
        return minSize + (maxSize - minSize) * (Math.abs(quantity) - minQuantity) / (maxQuantity - minQuantity);
      };

      const increaseMarkers = {
        x: increaseTrades.map(trade => new Date(trade.seen).toISOString()),
        y: increaseTrades.map(trade => trade.order_price),
        mode: 'markers',
        marker: {
          size: increaseTrades.map(trade => normalizeSize(trade.quantity)),
          color: 'lightskyblue'
        },
        name: 'Increase Qty',
        xaxis: 'x',
        yaxis: 'y'
      };

      const decreaseMarkers = {
        x: decreaseTrades.map(trade => new Date(trade.seen).toISOString()),
        y: decreaseTrades.map(trade => trade.order_price),
        mode: 'markers',
        marker: {
          size: decreaseTrades.map(trade => normalizeSize(trade.quantity)),
          color: 'orange'
        },
        name: 'Decrease Qty',
        xaxis: 'x',
        yaxis: 'y'
      };

      setChartData([
        candleData,
        avgEntryPrice,
        notionalValue,
        positionPnl,
        realizedPnl,
        //unrealizedPnl,
        increaseMarkers,
        decreaseMarkers
      ]);

      setLayout({
        ...customPlotlyTheme,
        title: `Position Management: ${symbol}`,
        autosize: true,
        width: 1100,
        height: 950,
        grid: { rows: 3, columns: 1, pattern: 'independent', roworder: 'top to bottom' },
        xaxis: { 
          rangeslider: { visible: false },
          domain: [0, 1],
          showgrid: true,
          gridcolor: 'rgba(42,46,57,0.8)',
        },
        yaxis: { 
          title: 'Price',
          domain: [0.55, 1],
          showgrid: true,
          gridcolor: 'rgba(42,46,57,0.8)',
          zerolinecolor: 'rgba(42,46,57,0.8)',
        },
        yaxis2: {
          title: 'Notional Value ($)',
          domain: [0.3, 0.45],
          showgrid: true,
          gridcolor: 'rgba(42,46,57,0.8)',
          zerolinecolor: 'rgba(42,46,57,0.8)',
        },
        yaxis3: {
          title: 'PnL ($)',
          domain: [0, 0.25],
          showgrid: true,
          gridcolor: 'rgba(42,46,57,0.8)',
          zerolinecolor: 'rgba(42,46,57,0.8)',
        },
        legend: {
          orientation: "h",
          yanchor: "bottom",
          y: -0.15,
          xanchor: "center",
          x: 0.5,
        },
        plot_bgcolor: 'rgb(19,23,34)',
        paper_bgcolor: 'rgb(19,23,34)',
      });

      setIsLoading(false);
    } else {
      console.error('Invalid data structure for PositionDetailChart', data);
      setIsLoading(false);
    }
  }, [data, symbol, traderName]);

  if (isLoading) {
    return <Spinner size="large" text="Loading chart data..." />;
  }

  if (!data || !data.candles || !data.trades) {
    console.error('No data available for Position Detail chart', { data, symbol, traderName });
    return <div>No data available for Position Detail chart</div>;
  }

  return (
    <Plot
      data={chartData}
      layout={layout}
      style={{ width: "100%", height: "100%" }}
      useResizeHandler={true}
      config={{ responsive: true }}
    />
  );
};

export default PositionDetailChart;