import React from "react";
import { Link, useRouteError } from "react-router-dom";
import { Home } from 'lucide-react';
import NavBar from "../components/common/Header";
import { Footer } from "../components/common/Footer";

export default function NotFound() {
    const error = useRouteError();

    return (
        <div className="flex flex-col min-h-screen bg-background">
            <NavBar />
            <main className="flex-grow flex items-center justify-center">
                <div className="text-center p-8 bg-gray-800 rounded-lg shadow-xl max-w-md w-full">
                    <h1 className="text-4xl font-bold text-primary mb-4">Oops!</h1>
                    <p className="text-lg text-gray-300 mb-4">
                        Sorry, an unexpected error has occurred.
                    </p>
                    <p className="text-sm text-gray-400 italic mb-6">
                        {error.statusText || error.message}
                    </p>
                    <Link 
                        to="/" 
                        className="inline-flex items-center px-4 py-2 bg-primary text-black font-semibold rounded-md hover:bg-secondary transition-colors duration-300"
                    >
                        <Home size={18} className="mr-2" />
                        Back to Homepage
                    </Link>
                </div>
            </main>
            <Footer />
        </div>
    );
}
