import React, { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';
import customPlotlyTheme from './plotlyTheme';
import { Spinner } from "../ui/spinner";

export const PnlBySymbolPlotly = ({ data }) => {
  const [chartData, setChartData] = useState([]);
  const [layout, setLayout] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  
  useEffect(() => {
    if (data && data.traderData && data.traderData.pnl_by_symbol) {
      const pnlBySymbol = data.traderData.pnl_by_symbol;
      
      // Sort the data by PnL values in ascending order (inverted)
      const sortedData = pnlBySymbol.symbols.map((symbol, index) => ({
        symbol,
        pnl: pnlBySymbol.pnl_values[index]
      })).sort((a, b) => a.pnl - b.pnl);

      setChartData([{
        type: 'bar',
        x: sortedData.map(item => item.symbol),
        y: sortedData.map(item => item.pnl),
        marker: {
          color: 'hsl(220, 70%, 50%)',
          width: 1,
          cornerradius: 5
        }
      }]);

      setLayout({
        ...customPlotlyTheme,
        xaxis: {
          title: 'Symbol',
          tickangle: -45
        },
        yaxis: {
          title: {
            text: 'PnL',
            standoff: 30
          }
        },
        autosize: true,
        height: 400,
        margin: { t: 10, r: 10, l: 70, b: 100 },
      });

      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  }, [data]);

  if (isLoading) {
    return <Spinner size="large" text="Loading..." />;
  }

  if (!data || !data.traderData || !data.traderData.pnl_by_symbol) {
    return <div>No data available for PnL by Symbol chart</div>;
  }

  return (
    <Plot
      data={chartData}
      layout={layout}
      style={{ width: '100%', height: '100%' }}
      useResizeHandler={true}
      config={{ responsive: true }}
    />
  );
};