import React from 'react';
import { Info } from 'lucide-react';
import { Tooltip } from '../ui/tooltip';

const SectionHeader = ({ title, description }) => {
  return (
    <div className="flex items-center mb-4">
      <h2 className="text-xl font-semibold mr-2">{title}</h2>
      <Tooltip content={description}>
        <Info className="h-5 w-5 text-gray-400 cursor-help" />
      </Tooltip>
    </div>
  );
};

export default SectionHeader;