export const shortenAddress = (address) => {
    if (address.length <= 10) {
      return address; // Return the address as is if it's too short to shorten
    }
    return `${address.slice(0, 6)}...${address.slice(-4)}`;
  };

export const formatHoldingTime = (hours) => {
  if (hours <= 24) {
    const h = Math.floor(hours);
    const m = Math.round((hours - h) * 60);
    return `${h}h ${m}m`;
  } else if (hours <= 24 * 31) {
    const d = Math.floor(hours / 24);
    const h = Math.round(hours % 24);
    return `${d}d ${h}h`;
  } else {
    const w = Math.floor(hours / (24 * 7));
    const d = Math.round((hours % (24 * 7)) / 24);
    return `${w}w ${d}d`;
  }
};

export const formatPercentage = (value) => (value * 100).toFixed(2) + '%';

export const formatNumber = (value) => {
  if (value == null) return 'N/A'; // Handle null or undefined
  return value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
};

export const formatEntryPrice = (value) => {
  const numValue = parseFloat(value);
  if (isNaN(numValue)) return 'N/A';

  if (numValue === 0) return '$0';

  let decimals;
  if (numValue < 0.1) {
    decimals = 6;
  } else if (numValue < 1) {
    decimals = 4;
  } else {
    decimals = 2;
  }
  
  return `$${numValue.toLocaleString('en-US', { minimumFractionDigits: decimals, maximumFractionDigits: decimals })}`;
};

export const copyToClipboard = (text, onSuccess, onError) => {
  navigator.clipboard.writeText(text).then(() => {
    if (onSuccess) onSuccess();
  }).catch(err => {
    console.error('Failed to copy: ', err);
    if (onError) onError(err);
  });
};

// 
export const formatUTCDate = (date) => {
  return new Date(date).toLocaleString('en-US', { timeZone: 'UTC', hour12: false });
};