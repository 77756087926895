import React, { useState } from 'react';
import { useTable, useExpanded } from 'react-table';
import { Link } from 'react-router-dom';
import { ChevronDown, ChevronRight } from 'lucide-react';

const Table = ({ columns, data, title, linkUrl, expandable = false, renderRowSubComponent, onRowExpand }) => {
  const [expandedRowId, setExpandedRowId] = useState(null);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
  } = useTable(
    {
      columns,
      data,
      autoResetExpanded: false,
    },
    useExpanded
  );

  const toggleRowExpanded = (rowId) => {
    setExpandedRowId((prevExpandedRowId) => {
      if (prevExpandedRowId === rowId) {
        // If clicking on the same row, close it
        return null;
      } else {
        // If clicking on a different row, expand it and close the previous one
        if (onRowExpand) {
          const row = rows.find(r => r.id === rowId);
          onRowExpand(rowId, row.original.position_id);
        }
        return rowId;
      }
    });
  };

  return (
    <div className="w-full">
      {title && <h2 className="text-lg text-primary font-bold mb-3">{title}</h2>}
      <div className="overflow-x-auto">
        <table {...getTableProps()} className="w-full border border-gray-600">
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {expandable && <th className="px-3 py-2 border-b text-gray-400 text-sm"></th>}
                {headerGroup.headers.map(column => (
                  <th
                    {...column.getHeaderProps()}
                    className={`px-3 py-2 border-b text-gray-400 text-sm ${column.align === 'left' ? 'text-left' : 'text-right'}`}
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row);
              const isExpanded = expandedRowId === row.id;
              return (
                <React.Fragment key={row.id}>
                  <tr {...row.getRowProps()} className="hover:bg-gray-800">
                    {expandable && (
                      <td className="px-3 py-2 border-b border-gray-600">
                        <button onClick={() => toggleRowExpanded(row.id)}>
                          {isExpanded ? <ChevronDown size={16} /> : <ChevronRight size={16} />}
                        </button>
                      </td>
                    )}
                    {row.cells.map(cell => (
                      <td
                        {...cell.getCellProps()}
                        className={`px-3 py-2 border-b border-gray-600 text-white text-sm ${
                          cell.column.align === 'left' ? 'text-left' :
                          cell.column.align === 'center' ? 'text-center' : 'text-right'
                        }`}
                      >
                        {linkUrl ? (
                          <Link to={linkUrl(row.original)}>
                            {cell.render('Cell')}
                          </Link>
                        ) : (
                          cell.render('Cell')
                        )}
                      </td>
                    ))}
                  </tr>
                  {expandable && isExpanded && (
                    <tr>
                      <td colSpan={visibleColumns.length + 1}>
                        {renderRowSubComponent({ row })}
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Table;