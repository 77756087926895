import { useState, useEffect } from 'react';
import { getProfileData } from '../services/jsonApi';

export const useProfileData = (id) => {
  const [traderData, setTraderData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getProfileData(id);
        const data = response[0]?.data || [];
        setTraderData(data);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  return { traderData, loading, error };
};