import React from "react";
import { cn } from "../../lib/utils";

const Spinner = ({ className, size = "default", text = "Loading..." }) => {
  const sizeClasses = {
    small: "w-4 h-4 border-2",
    default: "w-8 h-8 border-4",
    large: "w-12 h-12 border-4",
  };

  return (
    <div className="flex flex-col items-center">
      <div
        className={cn(
          "animate-spin rounded-full border-t-transparent border-primary",
          sizeClasses[size],
          className
        )}
      />
      <p className="mt-4 text-foreground animate-pulse">{text}</p>
    </div>
  );
};

export { Spinner };