// App.js
import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import Root from "./routes/Root";
import Profile from "./pages/Profile";
import Dashboard from "./pages/Dashboard";
import About from "./pages/About";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
        <Root />
    ),
    errorElement: <NotFound />,
    children: [
      {
        path: "/",
        element: <Home />,
        index: true,
      },
      {
        path: "/dashboard",
        element: <Dashboard />,
        index: true,
      },
      {
        path: "/dashboard/profile/:id",
        element: (
            <Profile />
        ),
      },
      {
        path: "/about",
        element: <About />,
      },
    ],
  },
]);

const App = () => {
  return <RouterProvider router={router} />;
};

export default App;