import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Menu, X } from 'lucide-react';
import Badge from '../ui/Badge';
import { TWITTER_PROFILE_URL } from '../../utils/config';

const XIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z" fill="currentColor"/>
  </svg>
);

const NavBar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const handleNavigation = (path) => {
    setIsMenuOpen(false);
    navigate(path);
  };

  return (
    <>
      <header className="bg-background p-2">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 w-full border border-gray-700 rounded-full bg-gradient-to-r from-gray-950 via-gray-900 to-gray-950">
          <nav className="flex justify-between items-center h-14">
            <Link to="/" className="flex items-center">
              <span className="text-xl font-semibold text-primary mr-2">
                HyperTracker
              </span>
              <Badge color="yellow">Beta</Badge>
            </Link>

            {/* Desktop menu - centered */}
            <div className="hidden md:flex absolute left-1/2 transform -translate-x-1/2 space-x-4">
              <Link to="/dashboard" className="text-primary transition-all duration-300 px-3 py-1 rounded-full border border-transparent hover:bg-gray-200 hover:bg-opacity-20">Dashboard</Link>
              <Link to="/about" className="text-primary transition-all duration-300 px-3 py-1 rounded-full border border-transparent hover:bg-gray-200 hover:bg-opacity-20">About</Link>
            </div>

            {/* X (Twitter) icon */}
            <div className="hidden md:flex items-center">
              <a href={TWITTER_PROFILE_URL} target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-gray-300">
                <XIcon />
              </a>
            </div>

            {/* Mobile menu button */}
            <div className="md:hidden flex items-center space-x-4">
              <a href={TWITTER_PROFILE_URL} target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-gray-300">
                <XIcon />
              </a>
              <button onClick={toggleMenu} className="text-primary">
                <Menu size={24} />
              </button>
            </div>
          </nav>
        </div>
      </header>

      {/* Mobile slide-in menu */}
      <div className={`fixed inset-y-0 right-0 w-64 bg-gray-900 transform ${isMenuOpen ? 'translate-x-0' : 'translate-x-full'} transition-transform duration-300 ease-in-out z-50`}>
        <div className="flex justify-end p-4">
          <button onClick={toggleMenu} className="text-primary">
            <X size={24} />
          </button>
        </div>
        <div className="px-4 py-2">
          <button onClick={() => handleNavigation('/dashboard')} className="block w-full text-left text-primary hover:text-custom-yellow transition-all duration-300 py-2">Dashboard</button>
          <button onClick={() => handleNavigation('/about')} className="block w-full text-left text-primary hover:text-custom-yellow transition-all duration-300 py-2">About</button>
        </div>
      </div>

      {/* Overlay */}
      {isMenuOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-40" onClick={toggleMenu}></div>
      )}
    </>
  );
};

export default NavBar;
