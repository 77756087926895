import React from 'react';

const HeroContent = () => {
  return (
    <div className="flex flex-col items-center justify-center px-4 py-4 md:py-4 lg:px-4 lg:py-4">
      <h1 className="mt-8 text-center text-4xl font-bold tracking-tight text-primary md:text-4xl lg:text-5xl max-w-2xl mx-auto">
        <span className="relative whitespace-nowrap">
          <span className="absolute bg-yellow-400 -left-2 -top-0 -bottom-0 -right-2 md:-left-2 md:-top-0 md:-bottom-0 md:-right-2 -rotate-1"></span>
          <span className="relative text-background">Get insights</span>
        </span>
        {" "} from the best traders on Hyperliquid
      </h1>
      <p className="mt-4 mb-6 text-center text-xl text-gray-500">
        Track. Analyze. Profit.
      </p>
    </div>
  );
};

export default HeroContent;